import { Pipe, PipeTransform } from '@angular/core';
import { KurzAreaUnitCode, KurzUnitFormattedNumberOptions, KurzUnitService } from '../../services/kurz-unit.service';

@Pipe({
  name: 'kurzArea'
})
export class KurzAreaPipe implements PipeTransform {

  constructor(private kurzUnitService: KurzUnitService) {}

  transform(value: string | number, areaUnitCode?: KurzAreaUnitCode): string {

    let num: number;

    switch(typeof value) {
      case 'number': num = value as number; break;
      case 'string': num = parseFloat(value); break;
      default: num = value as number;
    }

    const options: KurzUnitFormattedNumberOptions = {explicitUnitSystem: areaUnitCode};

    const res = this.kurzUnitService.getFormattedArea(num, options);

    return res;
  }

}
